.border-red {
    border: 1px solid red;
}

.nested-perfect-scroll .ps__rail-y {
    top: 0px !important;
}

.nested-scroll-div .ps__rail-y {
    top: 232px !important;
}

.custom-to-input-field-size span {
    font-size: 12px;
}

.custom-qualification-input-field-size span {
    font-size: 10px;
}

.custom-qualification-formcontrol .MuiPaper-root {
    min-width: 420px !important;
}

.remove-legend legend {
    width: 0 !important;
}