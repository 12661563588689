.login-additional-options {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form-check-input {
    position: relative !important;
    margin: 0 !important;
}

.remember-me {
    font-size: 10px;
    margin-left: 10px;
}

.login-additional-options .left-flex {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.login-additional-options .right-flex {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    cursor: pointer;
}

.forgot-pass {
    font-size: 10px;
    color: #0592FF;
}

.custom-btn {
    background-color: #20C1EE !important;
}

.login-container .form-control:focus {
    border-color: #E3E3E3 !important;
    /* box-shadow: none; */
}

.login-container .input-group-prepend {
    height: 38px;
}

.login-container .input-group:focus-within {
    box-shadow: red;
}