.custom-navbar {
    background: linear-gradient(0deg, #1B1A1A, #7B7B7B);
}

.custom-icon {
    color: white;
}

.dropdown .dropdown-toggle:after {
    color: white;
}

.navbar-user-wrapper {
    display: flex;
    flex-direction: row;
}

.navbar-username,
.navbar-role {
    color: #9D9D9D;
    font-size: 12px;
    line-height: 13px;
}

.navbar-button-container {
    display: flex;
    flex: 100;
    justify-content: center;
    align-items: center;
}

.navbar-button {
    flex-direction: row;
    padding: 5px 10px;
    background-color: white;
    margin-left: 7px;
    cursor: pointer;
}

.navbar-btn-text {
    font-size: 10;
    color: #5C5C5C;
    font-weight: 600;
    margin-left: 5px;
}

.dropdown-toggle::after {
    all: unset;
}

.dropdown-menu {
    border-radius: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.navbar-user-wrapper {
    display: flex;
    align-items: center;
    height: 30px;
}

@media screen and (max-width: 991px) {
    .user-navitem {
        display: none;
    }
}