.gx-5>div {
    padding: 0px 5px;
}

.custom-card-header {
    font-size: 12px;
    font-weight: 700;
    font-size: 18px;
}

.custom-card-header-second {
    font-size: 15px;
}

.custom-table th {
    font-weight: 500 !important;
}

.custom-table tr {
    font-size: 14px;
}

.custom-table th {
    font-weight: 600 !important;
    font-size: 10px !important;
    text-transform: none !important;
    color: #59BAD5;
}

.radio-label {
    /* margin: 0px 10px; */
    /* margin-top: 1px; */
    /* margin-bottom: 5px; */
    font-size: 10px;
    margin-left: 5px;
}

.radio-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.radio-buttons-and-label {
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 5px;
}

.radio-btn {
    transform: translateY(3px);
}

.custom-input-label {
    margin-top: 12px;
    margin-left: 5px;
    color: #5C5C5C !important;
    font-weight: 500;
}

.custom-input {
    border-color: #757575 !important;
}

.custom-select-input {
    margin-bottom: 10px;
    border-color: #757575;
}

.css-2b097c-container div {
    border-color: #757575 !important;
}

.search-clear-btns-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.notification-box {
    padding: 15px;
    background-color: #66C4DE;
    border-radius: 3px;
    margin-top: 15px;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.notification-message {
    color: white;
    flex: 1;
    display: flex;

}

.white-icon {
    color: white;
}

.big-white-icon {
    color: white;
    font-size: 25px !important;
    margin-right: 20px;
}

.icon-input-div {
    flex-direction: row;
    display: flex;
}

.icon-input {
    border-radius: 5px 0px 0px 5px !important;
    border-right-width: 0px !important;
}

.input-rigth-icon-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 5px 5px 0px !important;
    border: 1px solid #757575;
    border-left-width: 0;
    padding-right: 16px;
    background-color: #E3E3E3;
}

.calendar-form-group .rdtPicker:after,
.calendar-form-group .rdtPicker:before {
    display: none !important;
}

.calendar-div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

}

.custom-shadow-div {
    box-shadow: 0px 2px 11px -6px rgb(0 0 0 / 46%);
    -webkit-box-shadow: 0px 2px 11px -6px rgb(0 0 0 / 46%);
    -moz-box-shadow: 0px 2px 11px -6px rgb(0 0 0 / 46%);
}

.table-12-div {
    margin: 0px 5px;
    padding: 0px 5px;
}

.w-13 {
    width: 13%;
}

.custom-progress {
    height: 10px;
}

.custom-date-picker .rdtPicker,
.custom-date-picker {
    width: 100% !important;
}

.rdtDay {
    border-radius: 0%;
}

.rdtDay.rdtActive {
    background-color: white !important;
    color: #66C4DE !important;
    border-color: #66C4DE;
    border-bottom: 1.5px solid;
}

.rdtDay.rdtActive:hover {
    color: white !important;
}

.diary-div {
    /* margin: 20px 0px; */
    /* padding-bottom: 20px; */
    border-radius: 5px 5px 10px 10px;
    overflow: hidden;
    /* height: 11rem; */
}

.user-diary {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: #DDDDDD;
    padding: 5px 10px;
    align-items: center;
}

.user-diary-left {
    display: flex;
    flex: 1;
    align-items: center;
}

span.user-diary-txt {
    font-size: 12px;
    display: flex;
    justify-content: center;
}

.user-diary-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.user-diary-btn {
    cursor: pointer;
    background-color: #66C4DE;
    padding: 0px 15px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
}

.user-diary-btn-txt {
    font-size: 12px;
    color: white;
    font-weight: 700;
}

.diary-content-title-div {
    flex: 1;
    display: flex;
    justify-content: center;
}

span.diary-content-title {
    color: #5C5C5C;
}

.clickable-row {
    cursor: pointer;
}

.clickable-col {
    cursor: pointer;
}

.single-unallocated-dev-div {
    padding: 12px 17px;
}

.custom-card-detail {
    font-size: 12px;
    margin-top: 2px;
}

.header-bottom-top-gitter {
    margin: 5px 0px 5px 0px;
}

.exit-btn-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.date-time-row {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scroll-card {
    height: 31rem;
    /* height: 257px; */
}

/* .scroll-card .card-body {
    height: 212px;
} */

.scroll-card-nested-table {
    height: 30rem;
}

.scroll-card tr {
    font-size: 0.85rem;
}

.scroll-card-noti {
    /* height: 27.5rem; */
}

.scroll-card-search-top {
    height: 50rem;
}

.scroll-card-search-bottom {
    /* height: 44rem; */
}

td {
    padding: 5px !important;
}


/* input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #707070;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #707070;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #707070;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #707070;
} */

/* .clickable-underline {
    text-decoration: underline;
    cursor: pointer;
} */
.clickable-underline:hover {
    text-decoration: underline;
    cursor: pointer;
}