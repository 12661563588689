.select-with-left-icon fieldset {
    border-left-width: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.select-with-left-icon:hover fieldset {
    border-color: #C4C4C4 !important;
}

.select-with-left-icon.Mui-focused fieldset {
    border-color: #C4C4C4 !important;
    border-width: 1px !important;
    border-left-width: 0px !important;
}

.select-left-btn-wrap {
    border: 1px solid #C4C4C4 !important;
    border-radius: 0px !important;
    height: 40px !important;
    border-right-width: 0px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}